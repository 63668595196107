import React from "react"
import Layout from "../../components/Layout"
import Grid from "../../components/Grid"
import { Link } from "gatsby"
import css from "../setup.module.scss"
import fontType from "../../helpers/fontType.module.scss"

const metadata = {
  title: "Connect Shopify to ReferralCandy",
}

const ShopifySetup = (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container} centeredClassName={css.centered}>
        <h1 className={css.center}>Set Up ReferralCandy for Shopify</h1>
        <div className={css.video_container}>
          <iframe
            title="How To Install ReferralCandy Shopify Plugin for Shopify Stores"
            src="https://www.youtube.com/embed/93UEy4o5F7I?rel=0"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
        <p className={fontType.b1}>
          Step 1: Sign up for a new ReferralCandy account (1 min)
        </p>
        <p>
          Head over to the{" "}
          <a href="https://my.referralcandy.com/signup#shopify">sign up page</a>{" "}
          to register a new ReferralCandy account for your Shopify store.
        </p>
        <p className={fontType.b1}>
          Step 2: Install the ReferralCandy Shopify app (1 min)
        </p>
        <p>
          Enter your Shopify shop name or URL. You will be redirected to Shopify
          for authentication.
        </p>
        <p className={fontType.b1}>
          Step 3: Complete our setup wizard (5 mins)
        </p>
        <p>
          Once the app is installed, our setup wizard will take you through the
          necessary steps to configure your referral campaign. Don't worry about
          getting everything finalized in the first pass, you can always edit
          the settings again later, before you activate the campaign.{" "}
          <span role="img" aria-label="happy face">
            🙂
          </span>
        </p>
        <h3>
          Using a different platform? <Link to="/setup">View all guides</Link>
        </h3>
      </Grid>
    </Layout>
  )
}

export default ShopifySetup
